/* styles/tailwind.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*overflow*/
[overflow="clip"] {
  overflow: clip;
}
[overflow="hidden"] {
  overflow: hidden;
}
[overflow="visible"] {
  overflow: visible;
}

/*true 100vh*/
[true-vh] {
  height: calc(var(--vh, 1vh) * 100) !important;
}

body:hover [data-cursor-wrap] {
  opacity: 1;
}

/* init */
[data-start="hidden"] {
  visibility: hidden;
}

/* orb */
.orb canvas {
  width: 100% !important;
  height: 100% !important;
  background-color: transparent !important;
}

/* animations */
[stagger-scroll] .line {
  overflow: hidden;
}

/* homepage cross animation */
.s.is-hsc {
  overflow: clip;
}

@keyframes loopGradient {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

[gradient-evolve] {
  /* Initial gradient */
  background-image: linear-gradient(
    255deg,
    #facb0e,
    #f06ba8 30%,
    #78bae6 65%,
    #fff
  );
}

/* Pseudo element with final gradient */
[gradient-evolve]::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Final gradient */
  background-image: linear-gradient(
    255deg,
    #f06ba8,
    #facb0e 30%,
    #78bae6 65%,
    #fff
  );
  animation: loopGradient 5s infinite; /* 5s is the duration; adjust as needed */
}

@media only screen and (max-width: 992px) {
  [data-orb-wrap] {
    position: absolute;
  }
}


/* embla carousle scc start */
/* src/components/Carousel.css */
.embla {
}

.embla__container {
  display: flex;
}

.embla__slide {
  flex: 0 0 auto;
  width: 100%;
  padding: 0 16px;
  position: relative;
  /* box-sizing: border-box; */
}
.fullscreenFilter{
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
opacity: 0.5;
}
@keyframes pageFade{
  0%{
    opacity: 0;
  };
  100%{
    opacity: 1;
  }
}
.pageFadeAnimation{
  animation: pageFade 1.25s ease-in-out;
}